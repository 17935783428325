import React from 'react';
import { AiFillLinkedin, AiOutlineGithub} from 'react-icons/ai';

const Footer = () => {
  return (
    <div className="footer-container">
      <p>2022 Eric Gitangu - Amazon Clone Project</p>
      <p className="icons">
        <a href="https://linkedin.com/in/ericgitangu" style={{color:'#000 !important'}}>
          <AiFillLinkedin style={{cursor:'pointer'}} />
        </a>  
        <a href="https://github.com/ericgitangu" style={{color:'#000 !important'}}>
          <AiOutlineGithub style={{cursor:'pointer'}}/>
        </a>  
      </p>
    </div>
  )
}

export default Footer
